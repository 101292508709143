import React from "react";

import Close from "@components/Icons/Close";
import Button from "@components/Button";
import Image from "@components/Image";
import Arrow from "@components/Icons/Arrow";
import SubNavs from "./SubNavs";
import Dropdown from "@components/Dropdown";
import Icons from "@gstyles/styleguide/icons";

import styles from "./MenuModal.module.scss";
import cx from "classnames";
import _ from "lodash";
import { useTranslationContext } from "@providers/TranslationProvider";
import { getCurrentLocaleConfig } from "@translations/util";
import { useModalContext } from "@providers/ModalProvider";
import { useRouteContext } from "@providers/RouteProvider";
import helper from "@utils/helper";
import { useBookingContext } from "@providers/BookingProvider";
import useDocumentClick from "@hooks/useDocumentClick";
import { Dropdown as BFDropdown } from "@components/BookingForm";
import useWindowSize from "@hooks/useWindowSize";

const View = ({ NAVS, typeClickOutside = "document", hotel }: any) => {
  const { i18n } = useTranslationContext();
  const { router } = useRouteContext();
  const [isSwitch, $isSwitch] = React.useState(false);
  const [currentNav, $currentNav] = React.useState("");
  const { modalService } = useModalContext();
  const { booking } = useBookingContext();
  const menuArea: any = React.useRef({});

  const { width = 1280 } = useWindowSize();
  const handler = {
    onClickNav: (nav: any) => {
      const link = _.get(nav, "href", "");
      if (link) {
        return helper.isExternal(link) ? router.open(link) : router.push(link);
      }
      if (nav && nav.label && nav.label !== currentNav) {
        $isSwitch(true);
        _.debounce(() => {
          $currentNav(nav.label);
          $isSwitch(false);
        }, 400)();
      }
    },
    reset: () => {
      $currentNav("");
      $isSwitch(false);
    },
    onClose: () => {
      modalService.removeDOM(400, handler.reset);
      if (menuArea) {
        menuArea.current.classList.remove(styles.animSidebar);
      }
    },
    book: () => {
      booking.open({ hotel });
    },
    handleClickOutside: (event: any) => {
      const isArea =
        menuArea &&
        !_.isEmpty(menuArea.current) &&
        menuArea.current.contains(event.target);
      if (!isArea && width > 576) {
        handler.onClose();
      }
    },
  };
  React.useEffect(() => {
    if (modalService.visible && menuArea) {
      menuArea.current.classList.add(styles.animSidebar);
    }
  }, [_.get(modalService, "visible")]);

  useDocumentClick(handler.handleClickOutside, typeClickOutside);
  const current = _.find(
    _.get(NAVS, "data", []),
    (nav) => nav.label === currentNav
  );
  const data = _.get(current, "child", []);
  const image = _.get(current, "src", "");
  const subLabel = _.get(current, "subLabel", "");

  return (
    <>
      <div
        className={cx("modal-menu", styles.box, {
          [styles.active]: currentNav !== "",
          [styles.switch]: isSwitch,
        })}
      >
        <div ref={menuArea} className={cx("main-menu", styles.menu, {})}>
          <div className={styles.bgBlack}></div>
          <div className={cx("head", styles.head)}>
            <Close color={"#fff"} className="invisible" />
            <div className="flex items-center gap-10">
              <Dropdown
                className={styles.language}
                optionProps={{ className: styles.option }}
                itemProps={{ className: styles.currentItem }}
                value={i18n.language}
                options={[
                  {
                    label: <Icons name="flag-en" size={20} />,
                    value: "en",
                  },
                  { label: <Icons name="flag-vn" size={20} />, value: "vi" },
                ]}
                onChange={(opt: any) => {
                  const localeInstance = getCurrentLocaleConfig();
                  localeInstance.setLanguage(opt.value);
                  const baseUrl = localeInstance.withLangPrefix(
                    `${_.get(router, "location.pathname")}${_.get(
                      router,
                      "location.search"
                    )}`
                  );
                  router.languageTo(baseUrl);
                }}
              />
              <BFDropdown
                className={styles.dropdown}
                itemProps={{ className: styles.label }}
                optionsProps={{ className: styles.options }}
                optionProps={{ className: styles.option }}
                label={
                  <Button
                    label={i18n.t("Button.bookNow")}
                    // onClick={() => {
                    //   handler.book();
                    // }}
                  />
                }
              />
            </div>
          </div>
          <div className={cx("content", styles.content)}>
            {_.map(_.get(NAVS, "data", []), (nav: any, index: number) => (
              <div
                onClick={() => {
                  handler.onClickNav(nav);
                }}
                className={cx(
                  "ama-section-title flex items-center capitalize",
                  styles.nav,
                  {
                    [styles.active]: currentNav === nav.label,
                  }
                )}
                key={`nav_${index}`}
              >
                <div className="flex items-center amin-underline">
                  {nav.label}
                  {!_.isEmpty(nav.child) && <Arrow className={styles.arrow} />}
                </div>
              </div>
            ))}
          </div>
          <div className={cx("foot ama-label", styles.foot)}>
            <Button
              onClick={() => {
                router.push("privacy");
              }}
              label={i18n.t("Footer.privacyPolicy")}
              type="text"
            />
          </div>
        </div>
        <div className={cx("sub-menu", styles.subMenu)}>
          {!!image && (
            <div className={styles.imageBox}>
              <div className="absolute z-10 w-full h-full bg-black/70"></div>
              <Image
                wrapperProps={{ className: styles.image }}
                src={image}
                layout="fill"
                objectFit="cover"
              />
            </div>
          )}
          <div className={"absolute z-20 w-full h-full"}>
            {!_.isEmpty(data) && (
              <SubNavs
                data={data}
                onChange={handler.onClickNav}
                subLabel={subLabel}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
