const en = {
  Language: {
    vi: "Tiếng Việt",
    en: "English",
  },
  NAVS: {
    home: "Home",
    ourHotels: "Our hotels",
    contactUs: "Contact us",
    aboutUs: "About us",
    sustainability: "Sustainability",
    careers: "Careers",
    discoverOurHotels: "Discover Our Hotels",
    amanakiSaigonBoutique: "Amanaki Saigon Boutique",
    amanakiThaoDien: "Amanaki Thao Dien",
    theHotel: "the hotel",
    brandStory: "Brand story",
    artAmanaki: "Art & Amanaki",
    rooms: "Rooms",
    wellness: "Wellness",
    dining: "Dining",
    events: "Events",
    offers: "Offers",
    discoverSaigon: "Discover Saigon",
  },
  Form: {
    required: "Field required",
    emailInvalid: "Email invalid.",
    mustBeAccept: "Must be accepted",
    placeholderFirstName: "First name",
    placeholderLastName: "Last name",
    placeholderEmail: "Email address",
    placeholderPhone: "Phone number",
    placeholderEnquiry: "Select type of enquiry",
    placeholderWhichHotel: "Which hotel would you like to contact?",
    placeholderMessage: "Your message",
    placeholderDepartureDate: "Departure date",
    placeholderArrivalDate: "Arrival date",
    placeholderRoomType: "Select room type",
    placeholderAll: "All",
    labelPhone: "Phone",
  },
  Options: {
    Enquiry: {
      hotelReservation: "Hotel Reservation",
      wellnessReservation: "Wellness Reservation",
      meetingsEvents: "Meetings & Events",
      pressMedia: "Press & media",
      generalEnquiryFeedback: "General Enquiry & Feedback",
    },
  },
  Notification: {
    submitSuccess: "Your information has been submitted.",
  },
  Footer: {
    name: "Amanaki Hotels",
    address: "Thao Dien Ward, Thu Duc City, Ho Chi Minh City",
    address10: "10 Nguyen Dang Giai, Thao Dien Ward, Ho Chi Minh, VietNam",
    address65: "65 Ho Tung Mau, Ben Nghe Ward, District 1, HCMC",
    email: "info@amanaki.vn",
    email10: "info@amanaki.vn",
    email65: "sales@amanakihotels.com",
    phone: "(+84) 82 362 4488",
    phone10: "(+84) 82 362 4488",
    phone65: "(+84) 82 363 4488",
    tel: "Tel",
    ourHotels: "Our hotels",
    thaoDien: "Amanaki Thao Dien",
    boutiqueSaigon: "Amanaki Saigon Boutique",
    amanakiHotels: "Amanaki Hotels",
    getToKnowUs: "Get to know us",
    whoWeAre: "Who we are",
    sustainabilityImpact: "Sustainability impact",
    ourStories: "Our stories",
    explore: "Explore",
    careers: "Careers",
    ourOffice: "Our office",
    getInTouch: "Get in touch",
    contactUs: "Contact us",
    copyright: "© 2023 Amanaki Hotels. All Rights Reserved.",
    privacyPolicy: "Privacy Policy",
    adCookiePolicy: "Ad & Cookie Policy",
    legalNotices: "Legal Notices",
  },
  Header: {
    selectAHotel: "Select a hotel",
    aboutAmanaki: "About Amanaki",
    ourStories: "Blog",
    contactUs: "Contact us",
    ourBlog: "Blog",
    aboutUs: "About us",
    sustainability: "Sustainability",
    careers: "Careers",
  },
  Button: {
    bookNow: "Book now",
    discoverMore: "Discover more",
    moreDetail: "More detail",
    moreDetails: "More details",
    allOffer: "View all offers",
    viewThisRoom: "View this room",
    applyNow: "Apply now",
    viewMap: "View map",
    contactUs: "Contact us",
    moreAboutUs: "More about us",
    enterOurSpaces: "Enter our spaces",
    bookThisRoom: "Book this room",
    exploreArtsAndAmanaki: "Explore arts and amanaki",
    viewAllStories: "View all stories",
    viewAll: "View all",
    reserve: "Reserve",
    readMore: "Read more",
    exploreMore: "Explore more",
    viewCareers: "View careers",
    makeAnEnquire: "Make an enquire",
    getToKnowOurExecutiveChef: "Get to know our executive chef",
    visitOurRestaurant: "Visit our restaurant",
    seeOurFacilities: "See our facilities",
    submit: "Submit",
    checkTheMap: "Check the map",
    goToMap: "Go to map",
    requestForProposal: "Request for a proposal",
    bookDirectWithUs: "Book direct with us",
    back: "Back",
    backToTop: "Back To Top",
  },
  FAQs: {
    FAQs: "FAQs",
    slogan: "We are here to meet all your needs!",
  },
  Stories: {
    title: "Stories to Inspire",
    topic: {
      wellness: "Wellness",
      liveLikeLocal: "Live like a local",
      whatOn: "What's on",
    },
    lastestStories: "Latest Stories",
    interested: "Interested",
    exploreAllStories: "Explore All Stories",
    tags: "Tags",
    share: "Share the story",
  },
  About: {
    aboutAmanaki: "About Amanaki",
    aboutUs: "About us",
    sustainableDevelopment: "Sustainable Development",
    careers: "Careers",
    sustainable: {
      blogs: {
        heading: "You may also like",
      },
    },
  },
  Wellness: {
    wellness: "Wellness",
    facilitiesService: "Facilities & Services",
    gallery: "Gallery",
    hours: "Hours",
    reservations: "Reservations",
    location: "Location",
    wellbeingCentre: {
      wellbeingCentre: "Wellbeing Centre",
    },
    facilities: {
      facilities: "Facilities",
    },
  },
  Dinning: {
    dinning: "Dinning",
    restaurantInfo: {
      title: "Restaurant information",
      cuisine: {
        label: "Cuisine",
        fusionInspired: "Vietnamese Fusion Inspired",
        viewMenu: "View menu",
      },
      reservations: {
        label: "Reservations",
        phone: "082 841 1010",
        email: "info@amanaki.vn",
        address: "8 Nguyen Dang Giai, Thao Dien, District 2",
      },
      openingHours: {
        label: "Opening hours",
        allDay: "All Day",
        dinning: "06:00 - 22:00",
        lastOrder: "(last order at 21:45)",
      },
      executiveChef: {
        label: "Head Chef",
        name: "Dong Hoang Nam",
      },
      moreInfo: {
        label: "More Information",
        amanakiFacebook: "Amanaki Facebook",
      },
    },
  },
  MeetingAndEvents: {
    meetingAndEvents: "Meeting & Events",
    meetingAndCelebrations: "Events & Celebrations",
    labelBanner: "Creative space for inspiring events",
    overview: {
      title: "Events & Celebrations at Amanaki Thao Dien",
      desc: "Both literally and figuratively, 11:11 Espace exists right at the centre of Amanaki Thao Dien - the liminal co-existing space where stories are created and exchanged. The 250sqm exhibition and event space is where contemporary Vietnamese and international artists showcase their creative talent, as curated and presented by 11:11 Espace. Wishing to have your art & social event in style, come forward and let us hear you!",
      phone: "+84 82 362 4488",
      email: "info@amanaki.vn",
    },
    requestForProposal: {
      form: {
        fullName: "Full name",
        emailAddress: "Email Address",
        phoneNumber: "Phone Number",
        numberOfAttendees: "Expected Number of Attendees",
        desiredSetup: "Desired Set Up",
        desiredSetupOptions: JSON.stringify([
          { label: "Banquet", value: "Banquet" },
          { label: "Cocktail Reception", value: "CocktailReception" },
          { label: "Theatre", value: "Theatre" },
          { label: "Ushape", value: "Ushape" },
          { label: "Other", value: "Other" },
        ]),
        eventDate: "Event Date",
        note: "Event Overview, Comments, Question And/Or Special Requests",
        condition: {
          readAndAccept: "I have read and accept the",
          privacyPolicy: "Privacy policy",
          keepUpToDateInfo:
            "I would like to be kept up-to-date on news and information",
        },
      },
    },
  },
  BrandStory: {
    banner: {
      title: "Brand Story",
    },
  },
  Arts: {
    banner: {
      title: "Arts & Amanaki",
    },

    exhibitions: {
      tabs: JSON.stringify([
        { label: "UPCOMING EXHIBITION", value: "upcomingExhibition" },
        { label: "CURRENT EXHIBITION", value: "currentExhibition" },
        { label: "PREVIOUS EXHIBITION", value: "previousExhibition" },
      ]),
      status: {
        ongoing: "ONGOING",
        exhibition: "EXHIBITION",
      },
    },
  },
  LegalNotice: {
    banner: {
      title: "Legal Notice",
    },
    menuOptions: JSON.stringify([
      { label: "Privacy Policy", value: "PrivacyPolicy" },
      { label: "Cookies", value: "Cookies" },
      { label: "Best Rate Guaranteed", value: "BestRateGuaranteed" },
    ]),
    cookies: {
      heading: "Cookie",
      data: JSON.stringify([
        {
          title: `1. What is a cookie?`,
          content: `Cookies are small text files or pieces of information that are stored on your computer or mobile device <i>(such as a smartphone or tablet)</i> when you visit our websites. A cookie will usually contain the name of the website from which the cookie has come from, the “lifetime” of the cookie <i>(i.e. how long the cookie will remain on your device)</i>, and a value, which is usually a randomly generated unique number.`,
        },
        {
          title: `2. What do we use cookies for?`,
          content: `We use cookies to make our websites easier to use and to better tailor the websites and our products to your interests and needs. Cookies can do this because websites can read and write these files, enabling them to recognise you and remember important information that will make your use of a website more convenient <i>(for example by remembering preference settings)</i>. Cookies may also be used to help speed up your future activities and experience on our websites. We also use cookies to compile anonymous, aggregated statistics that allow us to understand how users use our websites and to help us improve the structure and content of our websites. We cannot identify you personally from this information.`,
        },
        {
          title: `3. What types of cookies do we use?`,
          content: `Two types of cookies may be used on the websites, which are called “session cookies” and “persistent cookies”. Session cookies are temporary cookies that remain on your device until you leave the Site. A persistent cookie remains on your device for much longer or until you manually delete it <i>(how long the cookie remains on your device will depend on the duration or “lifetime” of the specific cookie)</i>.
Third party cookies
We also use a number of suppliers that may also set cookies on your device on our behalf when you visit our websites to allow them to deliver the services they are providing. If you would like more information about these cookies, as well as information about how to opt-out of receiving such cookies, please see their privacy policies.`,
        },
        {
          title: `4. What cookies do we use?`,
          content: [
            {
              text: `Below we list the different types of cookies that may be used on Amanaki.vn website. Note that to the extent information collected through cookies constitutes personal information, the provisions in the Privacy Policy apply and complement this Cookie Policy.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Essential cookies:</span>
Essential cookies are necessary for the Site to work and enable you to move around it and to use its services and features. Without these absolutely necessary cookies, the Site will not perform as smoothly for you as we would like it to and we may not be able to provide the Site or certain services or features you request.
<span style="font-weight: 405;">Session</span> – Session cookies are used to maintain the state of the application. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Load Balancing</span> – Load balancing cookies are used to distribute the assets across the globe and lighten the server load. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">User ID</span> – User ID cookies are used to ensure that users only see their own information. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Security</span> – Security cookies are used for security controls and checks. These can be managed by accepting/refusing via browser setting.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Preference cookies</span>
Preference cookies collect information about your choices and preferences, and allow us to remember language or other local settings and customize the Site accordingly.
<span style="font-weight: 405;">Language</span> – Language cookies are used to store the language the user has selected, and to show the correct options. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Location</span> – The user’s approximate address <i>(city, state, country/region, postal code)</i> as determined by the IP address is retained in order to automatically select the proper country/region and show retailers and demonstration days within the area. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Mobile</span> – If the user is viewing the Site on a mobile device, a cookie is set to indicate that the main website was selected <i>(i.e., that the device is Flash-enabled)</i>, or the non-Flash mobile site. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Reference Cite</span> – The reference site is recorded to better understand the user’s preference. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Last visit and activity</span> – Date of last visit and activity and other information is recorded to be able to provide users with an update on “what’s changed on the site since your last visit,” and to better understand the user’s preferences. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Recently watched video</span> – Date and title of recently watched video is recorded to be able to better understand the user’s preferences. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Flash Cookie</span> – Flash cookies are used to enable audio and video content to be played. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Page history</span> – Page history cookies are used to track the sequence of pages the user has visited. If the user gets an error message while visiting the Site, the cookie information gets saved to a log file for error reporting and resolution. These can be managed by accepting/refusing via browser setting.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Social plug-in tracking cookies</span>
Such cookies are used to track members [and non-members] of social media networks for market research analytics, and product development.
<span style="font-weight: 405;">Facebook</span> – Cookies are used to track Facebook members [and non-members] for market research analytics, and product development. These can be managed by accepting/refusing via browser setting.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Analytics cookies</span>
Analytics cookies collect information about your use of the Site, and enable us to improve the way it works. For example, analytics cookies show us which are the most frequently visited pages on the Site, help us record any difficulties you have with the Site, and show us whether our advertising is effective or not. This allows us to see the overall patterns of usage on the Site, rather than the usage of a single person.
<span style="font-weight: 405;">Google analytics</span> – Google analytics cookies collect aggregate statistical data to improve Site presentation and navigation. Google supplements the aggregate data with demographics and interest information, so that we can better understand our visitors. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Adobe Omniture</span> – Adobe Omniture analytics cookies collect aggregate statistical data to improve Site presentation and navigation. These can be managed by accepting/refusing via browser setting.`,
            },
            {
              bullet: true,
              text: `<span style="font-weight: 405;">Cookie Marketing</span>
Advertising Cookies are used for marketing purposes.
<span style="font-weight: 405;">Advertising</span> – Deliver behavioural/targeted advertising. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Market analysis</span> – Conduct market analysis. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Campaign / promotion</span> – Measure effectiveness of campaign. These can be managed by accepting/refusing via browser setting.
<span style="font-weight: 405;">Fraud detection</span> – Detect click fraud. These can be managed by accepting/refusing via browser setting.`,
            },
          ],
        },
        {
          title: `5. How to manage cookies?`,
          content: `Although most internet browsers are initially set up to automatically accept cookies, most internet browsers allow you to change the settings to block cookies or to alert you when cookies are being sent to your device.
In addition to the options provided above, you may refuse, accept or remove cookies from the Site at any time by activating or accessing the setting on your browser. Information about the procedure to follow in order to enable, disable or remove cookies can be found on your Internet browser provider’s website via your help screen.
Please be aware that if cookies are disabled or removed, not all features of the Site may operate as intended, for example you may not be able to visit certain areas of our Site or you may not receive personalised information when you visit the Site.
If you use different devices to view and access the websites <i>(e.g. your computer, smartphone, tablet etc.)</i> you will need to ensure that each browser on each device is adjusted to suit your cookie preferences.
If you want to clean out all cookies left behind by the websites you have visited, you can find the third party websites that clean out tracking cookies.
These third party websites are not under our control and we do not make any representations or warranties in relation to these websites. If you decide to access these third party websites, we recommend that you review the respective terms and conditions and privacy policy of any third party site before you use the site.`,
        },
        {
          title: `6. Pixel tags`,
          content: `Some of the pages you visit on the Site may also collect information through the use of pixel tags <i>(also called clear gifs)</i> that may be shared with third parties which directly support our promotional activities and website development. For example, website usage information about visitors to our websites may be shared with our third party advertising agency to better target Internet banner advertisements on our websites. The information collected through the use of these pixel tags is not, however, personally identifiable, although it could be linked to your personal information.`,
        },
        {
          title: `7. Contact us`,
          content: `If you have any questions about this Cookie Policy, please contact us at <a href="mailto:info@amanaki.vn" style="color: #af915e">info@amanaki.vn</a>. Please note that email communications are not always secure, so please do not include credit card information or sensitive information in your emails to us.`,
        },
      ]),
    },
    bestRateGuaranteed: {
      heading: "Best rate guaranteed",
      title: "BOOK DIRECTLY WITH AMANAKI FOR THE BEST RATE GUARANTEED.",
      data: JSON.stringify([
        {
          content: `Your stay with Amanaki, from start to finish, is our priority. That journey begins here when you book your stay directly on our official website, you’ll be guaranteed:`,
        },
        {
          content: [
            `The lowest rate possible for your stay`,
            `The most flexible cancellation terms`,
            `Access to our dedicated reservations specialists to help with your booking`,
            `Access to the best service throughout your stay`,
            `If you find a lower qualifying rate within 24 hours of making your reservation, we’ll match the lower rate.`,
          ],
        },
      ]),
    },
  },
  HomeHotel10: {
    experiencing: {
      title: "Experiencing Amanaki",
    },
  },
  Offers: {
    offer: "Offer",
  },
  Offer: {
    valid: "Valid till {{date}}",
    otherOffer: "Other Offers",
  },
  ContactUs: {
    description: `Please get in touch if you have any questions about our hotel, hotel reservation or booking at one of our restaurant or wellbeing centre.`,
    getInTouch: "Get in touch",
    descInTouch: `We would be delighted to hear from you so please use the details below to contact us.`,
    iConfirm: "I confirm that I have read and understood Amanaki Hotels",
    privacy: "Privacy policy",
    acceptReceiveInfomation:
      "Yes! I would like to receive special offers and promotions from Amanaki Hotels including hotel, dining and spa promotions",
    addressAmanakiThaoDien: `No 10, Nguyen Đang Giai, Thao Dien Ward, Thu Duc City, Ho Chi Minh City`,
    addressAmanakiSaigonBoutique: `65 Ho Tung Mau, Ben Nghe Ward, District 1, HCMC`,
  },
  Discover: {
    title: "Discover Saigon",
    description:
      "“Every building, every storefront, seemed to open onto a different world, compressing all the variety of human life into a jumble of possibilities made all the richer by the conjunctions.”<0/>Rebecca Soln it",
    BlockLocation: {
      distanceAmanaki: "Direction from Amanaki: ",
      checkMap: "Check the map",
    },
    BlockOurLocation: {
      ourLocation: "our location",
      getting: "Getting to Amanaki<0/>Thao Dien hotel",
      address:
        "10 Nguyen Dang Giai street, Thao Dien Ward, Thu Duc city, Ho Chi Minh city",
      phone: "+84(0)8 23624488",
      airport: "Tan Son Nhat International Airport",
      airportDescription: `From Tan Son Nhat International Airport<0/>
Distance from property: 13 KM<0/>
Airport Shuttle service: 500.000VND/ ride`,
      tel: "Tel",
    },
  },
  Breadcrumb: {
    stories: "Our stories",
    category: "category",
    amanakiThaoDien: "Amanaki Thao Dien",
    "amanaki-thao-dien": "Amanaki Thao Dien",
    "amanaki-boutique-saigon": "Amanaki Saigon Boutique",
    theHotel: "The Hotel",
  },
  Rooms: {
    Signature: "Signature",
    Executive: "Executive",
    Premium: "Premium",
    FamilySuite: "Family Suite",
    otherRooms: "Other Rooms",
    theDetails: "The Details",
    utilities: "Utilities",
    amenities: "Amenities",
    checkIn: "Check-in",
    checkOut: "Check-out",
    visitOurRooms: "Visit our rooms",
    Banner: {
      title: "Visit Our Rooms",
      description: `Slow down and eyes wide open.<0/>As inside, a piece of history is gently looking at you.`,
    },
  },
  DateFormat: {
    Default: "DD/MM/YYYY",
    FullDisplay: "HH:mm:ss - DD/MM/YYYY",
    Unit: {
      second: "second",
      minute: "minute",
      hour: "hour",
    },
    Time: "LT",
  },
  SearchControl: {
    room: "Room",
    adults: "Adults",
    children: "Children",
    bestPrice: "Best price guaranteed",
    checkAvailability: "Check availability",
  },
  Time: {
    minutes: "{{n}} minutes",
  },
  Filter: {
    clearFilter: "Clear Filter",
    categoryPlaceholder: "Category",
    placeholderCategory: "Category",
    sortPlaceholder: "Sort",
    lastestLabel: "Lastest article",
    oldestLabel: "Oldest article",
  },
};
export default en;
